

















































































































































import {apiOrderActive, apiOrderList,apiOrderDel} from "@/api/card_order/order";
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging, debounce } from "@/utils/util";
import ExportData from "@/components/export-data/index.vue";

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
    },
})
export default class electronCard extends Vue {
	apiOrderList=apiOrderList;
    @Prop() semiSeq!: string;

    pager: RequestPaging = new RequestPaging();
    searchObj: any = {
		sn: "",
		customer_name: "",
		admin_name: "",
		create_time: "",
		welfare_name: "",
		shop_name: "",
		page_no:1,
		page_size:20,
    };

    tableData: any[] = [
    ];
    created() {
    	this.getCardList();
	}
    // 列表
    getCardList(page?: number) {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiOrderList,
            params: {
                ...this.searchObj,
            },
        });
    }
	async toActive(item_id:any){
		let res=await apiOrderActive({ids:[item_id],status:1})
		this.getCardList();
	}
	async toDel(item_id:any){
		let res=await apiOrderDel({ids:[item_id],status:2})
		this.getCardList();
	}
    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getCardList(1);
    }
    // 去编辑商城
    toCardEdit(id: number | any): void {
        this.$router.push({
            path: "/card/pointManage/pointOrderDetail",
            query: {
                id: id,
            },
        });
    }
	toSendList(id: number | any): void {
		this.$router.push({
			path: "/card/pointManage/sendList",
			query: {
				id: id,
			},
		});
	}
}
